<template>
  <div>
    <div>
      <!-- <button class="btnClass" @click="toStart">点击抽奖</button> -->
      <img src="@/assets/img/ball/lottery_bg2.png" alt="" />
      <!-- 蛋区 -->
      <div class="gashapon">
        <img class="spinBox" src="@/assets/img/ball/glass_light.png" alt="" />
        <img
          :class="roteShow ? 'spinBtn rotate' : 'spinBtn'"
          @click="toStart"
          src="@/assets/img/ball/glass_btn.png"
          alt=""
        />
        <div ref="eggBody">
          <div
            class="egg_box"
            v-for="(item, index) in imgIndex"
            :style="calcStyle(index)"
            :class="`egg_box${index + 1}`"
            :key="index"
          >
            <img
              :src="require(`../../assets/img/ball/ball${item}.png`)"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 出口 -->
      <div class="hit_box" ref="hitEggBox">
        <!-- 出口蛋 -->
        <div class="hit_egg" ref="hitEgg">
          <!-- 光 -->
          <!-- <div class="light_box" v-show="lightShow">
            <img class="light_img" src="../../../assets/egg/e_sun.jpg" alt="" />
          </div>
          <img src="../../../assets/egg/egg_top.png" alt="" />
          <img src="../../../assets/egg/egg_foot.png" alt="" /> -->
        </div>
      </div>
    </div>
    <!-- <van-overlay :show="show" :lock-scroll="true" /> -->
  </div>
</template>

<script>
import { Overlay } from "vant";
export default {
  name: "EggMachine",
  components: {
    [Overlay.name]: Overlay,
  },
  data() {
    return {
      imgIndex: [1, 2, 3, 2, 2, 3, 1, 1, 2, 1, 3, 3, 2, 1, 3],
      moveIng: false,
      lastSite: {},
      show: false,
      lightShow: false,
      roteShow: false,
    };
  },
  created() {},
  async mounted() {
    this.$nextTick(() => {
      // 获取中心位置
      setTimeout(() => {
        this.getEggEndLocation();
      }, 400);
    });
  },
  computed: {
    calcStyle() {
      return function(index) {
        let top =
          index < 4
            ? [1, 2].includes(index)
              ? "78%"
              : "71%"
            : index < 8
            ? [5, 6].includes(index)
              ? "61%"
              : `${this.getRandomArbitrary(54, 56)}%`
            : `${this.getRandomArbitrary(45, 46)}%`;
        return {
          width: "18%",
          transform: `rotate(${this.getRandomArbitrary(8, 20) * 15}deg)`,
          top,
        };
      };
    },
  },
  methods: {
    async toStart() {
      this.setNodeClass(true);
      this.roteShow = true;
      await this.delay(3000);
      this.setNodeClass(false);
      this.roteShow = false;
      // 页面滚动到顶部，保证动画在中
      window.scroll(0, 0);
      // 下降
      //   this.eggDown();
    },
    // 节点class处理
    async setNodeClass(add = true) {
      const eggChild = this.$refs.eggBody.childNodes;
      for (let i = 0; i < 15; i++) {
        const itemBoxStyle = eggChild[i].style;
        add
          ? (itemBoxStyle.animation = `move${i + 1} 1s 6 linear`)
          : (itemBoxStyle.animation = "");
      }
      this.moveIng = add;
    },
    // 下降
    // async eggDown() {
    //   const resNode = this.$refs.hitEgg;
    //   this.show = true;
    //   resNode.style.zIndex = "2";
    //   resNode.classList.add("resulteDown");
    //   await this.delay(1000);

    //   // 记录当前位置
    //   const { top, left } = resNode.getBoundingClientRect();

    //   // 设置转变
    //   this.$refs.hitEggBox.style.overflow = "visible";
    //   if (!Object.keys(this.lastSite).length) {
    //     this.getEggEndLocation();
    //   }
    //   resNode.style.transform = `translate3d(${this.lastSite.left -
    //     left}px, ${this.lastSite.top -
    //     top}px,0px) scale(${1.8}) rotate(-45deg)`;
    //   resNode.classList.add("active2");
    //   await this.delay(1800);
    //   this.openEgg();
    // },

    // 获取扭蛋结束中心位置
    getEggEndLocation() {
      const eggEnd = this.$refs.hitEgg;
      console.log(this.$refs.hitEgg);
      const style = {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) scale(1.8)",
        "z-index": "-1",
        opacity: 0,
      };
      for (const key in style) {
        if (Object.hasOwnProperty.call(style, key)) {
          eggEnd.style[key] = style[key];
        }
      }
      this.lastSite = this.getEleLocation(eggEnd);
      // 清除设置
      for (const key in style) {
        if (Object.hasOwnProperty.call(style, key)) {
          eggEnd.style[key] = "";
        }
      }
    },
    // 打开动画
    async openEgg() {
      // 测试
      const resNode = this.$refs.hitEgg;
      const resNodeImg = resNode.childNodes;
      // 添加打开动画
      resNodeImg[1].classList.add("eggOpenTop");
      resNodeImg[2].classList.add("eggOpenBottom");
      await this.delay(1000);
      this.lightShow = true;
      await this.delay(1000);
      // console.log('抽奖结束')

      this.$emit("darw-succes");
      // 复位
      this.$refs.hitEggBox.style.overflow = "hidden";
      resNodeImg[1].classList.remove("eggOpenTop");
      resNodeImg[2].classList.remove("eggOpenBottom");
      resNode.classList.remove("resulteDown");
      resNode.classList.remove("active2");
      resNode.style.transform = "";
      resNode.style.zIndex = "";
      this.show = false;
      this.lightShow = false;
    },
    // 获取元素位置
    getEleLocation(ele) {
      const { top, left } = ele.getBoundingClientRect();
      return { top, left };
    },
    // 延迟函数
    async delay(time = 3000) {
      return new Promise((res) => {
        setTimeout(() => {
          res();
        }, time);
      });
    },
    //
    getRandomArbitrary(min = 0, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值
    },
  },
};
</script>

<style lang="less" scoped>
.btnClass {
  position: absolute;
}
.active2 {
  transition: all 1.4s linear;
}
.eggOpenTop {
  animation: upOpen 1.2s linear;
  animation-fill-mode: forwards;
}
.eggOpenBottom {
  animation: bottomOpen 1.2s linear;
  animation-fill-mode: forwards;
}
img {
  width: 100%;
}
.gashapon {
  //   width: 14.56rem;
  //   height: 14.56rem;
  width: 62vw;
  height: 62vw;
  //   background: url("../../assets/img/ball/glass_bg.png") no-repeat center;
  //   background-size: 100% 100%;
  background-color: #c30004;
  position: absolute;
  //   top: 13.28rem;
  top: 56vw;
  left: 50%;
  transform: translateX(-50%);
  //   background-color: transparent;
  border-radius: 50%;
  z-index: 10;
}
.egg_box {
  position: absolute;
  z-index: 1;
}
.egg_box img {
  width: 100%;
}
.hit_egg {
  position: absolute;
  width: 0.8rem;
  top: -80%;
  left: 49%;
  transform: rotate(-45deg) translateX(-50%);
  transform-origin: 50% 50%;
  img {
    width: 100%;
    &:nth-child(3) {
      margin-top: -0.1rem;
    }
  }
  .light_box {
    position: absolute;
    width: 1rem;
    overflow: hidden;
    top: -0.1rem;
    .light_img {
      animation: rotateAni 0.8s infinite linear;
    }
  }
}
.hit_box {
  position: absolute;
  width: 1.6rem;
  height: 2rem;
  top: 71%;
  left: 29%;
  overflow: hidden;
}
.resulteDown {
  animation: upInDown 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-fill-mode: forwards;
}
.spinBox {
  width: 100%;
  position: absolute;
  z-index: 3;
}
.spinBtn {
  width: 3.93rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(0);
  z-index: 10;
  transition: all 0.3s linear;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  -webkit-transtion: all 0.3s linear;
}
.rotate {
  //旋转后的位置
  transform: translate(-50%, -50%) rotate(90deg);
  transition: all 0.3s linear;
  -webkit-transform: translate(-50%, -50%) rotate(90deg); //还是兼容一下
  -webkit-transtion: all 0.3s linear;
}

/* ------- 15个球 -------   */
@domLeftList: 16%, 28%, 48%, 64%, 21%, 34%, 48%, 60%, 64%, 50%, 30%, 17%, 7%,
  27%, 80%;
@len: length(@domLeftList);
.loop(@i) when (@i <= @len) {
  .egg_box@{i} {
    left: extract(@domLeftList, @i);
  }
  .loop(@i+1);
}
.loop(1);
</style>
<style>
/* 放大动画 */
@keyframes rotateAni {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
}

/* 打开动画 */
@keyframes upOpen {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(5px, 0px);
  }
  50% {
    transform: translate(-5px, 0px);
  }
  70% {
    transform-origin: -10% 85%;
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: rotate(-30deg);
    transform-origin: -10% 85%;
  }
}

@keyframes bottomOpen {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-5px, 0px);
  }
  50% {
    transform: translate(5px, 0px);
  }
  70% {
    transform-origin: 6% 16%;
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: rotate(30deg);
    transform-origin: 6% 16%;
  }
}

/* 下降动画 */
@keyframes upInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 43%;
  }
}

/* 球滚动 */
/* @numList: range(1, 15);
  each(
    @numList,
    {@keyframes move{0% {transform: rotate(-30deg) ; left: 12.7%; top:
      57.9%;} 44% {transform: rotate(110deg) ; left: 52.2%; top: 21.8%;} 64% {transform:
      rotate(56deg) ; left: 72%; top: 38%;} 100% {transform: rotate(-30deg) ;
      left: 12.7%; top: 57.9%;}}}
  ); */

@keyframes move1 {
  0% {
    transform: rotate(-30deg);
    left: 12.7%;
    top: 57.9%;
  }

  26% {
    transform: rotate(60deg);
    left: 41.2%;
    top: 8.9%;
  }

  44% {
    transform: rotate(110deg);
    left: 52.2%;
    top: 21.8%;
  }

  64% {
    transform: rotate(56deg);
    left: 72%;
    top: 38%;
  }

  100% {
    transform: rotate(-30deg);
    left: 12.7%;
    top: 57.9%;
  }
}

@keyframes move2 {
  0% {
    transform: rotate(85deg);
    left: 31.2%;
    top: 77.9%;
  }

  23% {
    transform: rotate(210deg);
    left: 70%;
    top: 36%;
  }

  45% {
    transform: rotate(120deg);
    left: 45%;
    top: 8%;
  }

  72% {
    transform: rotate(30deg);
    left: 8%;
    top: 34%;
  }

  100% {
    transform: rotate(85deg);
    left: 31.2%;
    top: 57.9%;
  }
}

@keyframes move3 {
  0% {
    transform: rotate(-10deg);
    left: 50%;
    top: 57.9%;
  }

  38% {
    transform: rotate(-30deg);
    left: 38%;
    top: 11.4%;
  }

  65% {
    transform: rotate(-50deg);
    left: 7%;
    top: 38.7%;
  }

  100% {
    transform: rotate(-10deg);
    left: 50%;
    top: 57.9%;
  }
}

@keyframes move4 {
  0% {
    transform: rotate(20deg);
    left: 65%;
    top: 59.9%;
  }

  35% {
    transform: rotate(-30deg);
    left: 53.4%;
    top: 11.3%;
  }

  64% {
    transform: rotate(-53deg);
    left: 24.3%;
    top: 56%;
  }

  100% {
    transform: rotate(20deg);
    left: 65%;
    top: 59.9%;
  }
}

@keyframes move5 {
  0% {
    transform: rotate(-65deg);
    left: 61.4%;
    top: 38%;
  }

  29% {
    transform: rotate(-180deg);
    left: 40%;
    top: 11.5%;
  }

  53% {
    transform: rotate(-222deg);
    left: 9%;
    top: 41.3%;
  }

  76% {
    transform: rotate(-160deg);
    left: 21.8%;
    top: 57.9%;
  }

  100% {
    transform: rotate(-65deg);
    left: 61.4%;
    top: 38%;
  }
}

@keyframes move6 {
  0% {
    transform: rotate(16deg);
    left: 44.2%;
    top: 42%;
  }

  28% {
    transform: rotate(-60deg);
    left: 18%;
    top: 57%;
  }

  40% {
    transform: rotate(-45deg);
    left: 8%;
    top: 41.3%;
  }

  80% {
    transform: rotate(70deg);
    left: 52.7%;
    top: 9.9%;
  }

  100% {
    transform: rotate(16deg);
    left: 44.2%;
    top: 42%;
  }
}

@keyframes move7 {
  0% {
    transform: rotate(-13deg);
    left: 27.5%;
    top: 39.9%;
  }

  17% {
    transform: rotate(50deg);
    left: 37.5%;
    top: 57.9%;
  }

  44% {
    transform: rotate(75deg);
    left: 75%;
    top: 41.3%;
  }

  67% {
    transform: rotate(42deg);
    left: 50.18%;
    top: 8%;
  }

  100% {
    transform: rotate(-13deg);
    left: 27.5%;
    top: 39.9%;
  }
}

@keyframes move8 {
  0% {
    transform: rotate(46deg);
    left: 14.4%;
    top: 53.9%;
  }

  20% {
    transform: rotate(97deg);
    left: 45.6%;
    top: 7.8%;
  }

  45% {
    transform: rotate(143deg);
    left: 76.8%;
    top: 41.6%;
  }

  65% {
    transform: rotate(85deg);
    left: 64.6%;
    top: 57%;
  }

  100% {
    transform: rotate(46deg);
    left: 14.4%;
    top: 33.9%;
  }
}

@keyframes move9 {
  0% {
    transform: rotate(65deg);
    left: 36.4%;
    top: 70%;
  }

  41% {
    transform: rotate(130deg);
    left: 74.3%;
    top: 42.9%;
  }

  76% {
    transform: rotate(94deg);
    left: 46.5%;
    top: 57.9%;
  }

  100% {
    transform: rotate(65deg);
    left: 36.4%;
    top: 20%;
  }
}

@keyframes move10 {
  0% {
    transform: rotate(-92deg);
    left: 53.6%;
    top: 22.11%;
  }

  20% {
    transform: rotate(-142deg);
    left: 37%;
    top: 58.5%;
  }

  47% {
    transform: rotate(-198deg);
    left: 6.7%;
    top: 37.3%;
  }

  67% {
    transform: rotate(-135deg);
    left: 23%;
    top: 10.7%;
  }

  100% {
    transform: rotate(-92deg);
    left: 53.6%;
    top: 22.11%;
  }
}
@keyframes move11 {
  0% {
    transform: rotate(-92deg);
    left: 53.6%;
    top: 62.11%;
  }

  20% {
    transform: rotate(-142deg);
    left: 37%;
    top: 58.5%;
  }

  47% {
    transform: rotate(-198deg);
    left: 6.7%;
    top: 37.3%;
  }

  67% {
    transform: rotate(-135deg);
    left: 23%;
    top: 10.7%;
  }

  100% {
    transform: rotate(-92deg);
    left: 53.6%;
    top: 62.11%;
  }
}
@keyframes move12 {
  0% {
    transform: rotate(-30deg);
    left: 53.6%;
    top: 68%;
  }

  20% {
    transform: rotate(10deg);
    left: 37%;
    top: 58.5%;
  }

  47% {
    transform: rotate(95deg);
    left: 6.7%;
    top: 37.3%;
  }

  67% {
    transform: rotate(60deg);
    left: 23%;
    top: 10.7%;
  }

  100% {
    transform: rotate(-30deg);
    left: 53.6%;
    top: 68%;
  }
}
@keyframes move13 {
  0% {
    transform: rotate(-92deg);
    left: 40.6%;
    top: 70%;
  }

  20% {
    transform: rotate(-142deg);
    left: 37%;
    top: 62%;
  }

  47% {
    transform: rotate(-198deg);
    left: 6.7%;
    top: 37.3%;
  }

  67% {
    transform: rotate(-135deg);
    left: 28%;
    top: 12.7%;
  }

  100% {
    transform: rotate(-92deg);
    left: 30%;
    top: 72%;
  }
}
@keyframes move14 {
  0% {
    transform: rotate(30deg);
    left: 53.6%;
    top: 12%;
  }

  20% {
    transform: rotate(42deg);
    left: 37%;
    top: 58.5%;
  }

  47% {
    transform: rotate(136deg);
    left: 6.7%;
    top: 37.3%;
  }

  67% {
    transform: rotate(80deg);
    left: 23%;
    top: 10.7%;
  }

  100% {
    transform: rotate(15deg);
    left: 53.6%;
    top: 12%;
  }
}
@keyframes move15 {
  0% {
    transform: rotate(22deg);
    left: 70%;
    top: 5%;
  }

  20% {
    transform: rotate(58deg);
    left: 37%;
    top: 72%;
  }

  47% {
    transform: rotate(180deg);
    left: 16%;
    top: 37.3%;
  }

  67% {
    transform: rotate(110deg);
    left: 23%;
    top: 28%;
  }

  100% {
    transform: rotate(30deg);
    left: 72%;
    top: 5%;
  }
}
</style>
